.sidebar-container {
  flex-direction: column;
}

.sidebar-text {
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0px 0 0;
}

.sidebar-content {
  width: 300px;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px 0 0 20px;
  position: absolute;
  top: 0;
  right: 100;
}

.sidebar-panel-tab {
  color: white;
  transition: background-color 0.4s ease-out, color 0.4s ease-out;
  margin: 10px 0 10px;
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  min-height: 70px;
  width: 100px;
  cursor: pointer;
}

.sidebar-panel-tab:hover,
.sidebar-panel-tab.active {
  background: linear-gradient(90deg, var(--color-gradEnd) 0%, var(--color-gradStart) 100%);
  transition: background 0.6s ease-out, color 0.5s ease-out;
  fill: black !important;
  border-right: #ffffff 2px solid;
}

.sidebar-panel-tab-icon {
  margin-top: 10px;
  font-size: 20px;
}

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;