.voice-overs-wrapper {
  -webkit-transition: all 0.275s;
  transition: all 0.275s;

  .voice-overs {
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
    max-height: 0;
    overflow: hidden;
    &.visible {
      max-height: 1000px;
      overflow: visible;
    }
  }
}

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;