.ant-select-selector {
  // width: 200px !important; /* Adjust the width as needed */
  // height: 10px !important; /* Adjust the height as needed */
  max-height: 28px !important;
  font-size: 12px !important; /* Adjust the font size for the text inside the selector */
}

/* Targeting the placeholder text specifically */
.ant-select-selection-placeholder {
  font-size: 10px !important;
}

.ant-select-item-option-content {
  font-size: 10px !important; /* Adjust to your preferred font size */
}

// .custom-select-option-content .ant-tag {
//   font-size: 120px !important; /* Set the desired font size for tags */
// }

// :where(.css-dev-only-do-not-override-1rqnfsa).ant-tag {
//   display: initial !important;
// }

// .ant-select {
//   width: 30px !important; // Adjust the width as needed
// }

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;